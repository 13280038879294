<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="allPeserta"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :search="search"
      item-key="id_pst"
      show-expand
      class="elevation-1"
    >
      <template v-slot:[`top`]>
        <v-text-field
          v-model="search"
          label="Search (UPPER CASE ONLY)"
          class="mx-4"
          append-icon="mdi-magnify"
        >
        </v-text-field>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="updatePeserta">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-subheader>Peserta Profil</v-subheader>
            <v-col cols="12" class="d-flex align-center">
              <v-avatar size="99" class="ma-1" tile>
                <img :src="pst.foto_upload" />
              </v-avatar>
            </v-col>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.nm_pst"
                      label="Nama Peserta"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.nik"
                      label="Nomor Induk Kependudukan"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.tempat_lahir"
                      label="Tempat Lahir"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.tanggal_lahir"
                      label="Tanggal Lahir"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-text-field
                      v-model="pst.agama"
                      label="Agama"
                    ></v-text-field> -->
                    <v-autocomplete
                      :items="agama"
                      item-value="id_agama"
                      color="blue"
                      item-text="name"
                      label="Agama"
                      v-model="pst.agama"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.jenis_kelamin"
                      label="Jenis Kelamin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.pekerjaan"
                      label="Pekerjaan"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.no_telp"
                      label="No Telepon"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.alamat"
                      label="Alamat"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-subheader>Asal Pendidikan</v-subheader>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-text-field
                      v-model="pst.jenis_daftar"
                      label="Jenis Daftar"
                    ></v-text-field> -->
                    <v-select
                      :items="asalPendidikan"
                      item-text="name"
                      item-value="id"
                      label="Janis Daftar"
                      v-model="pst.jenis_daftar"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.nisn_nim"
                      label="NIM Asal/NIS"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    v-if="pst.jenis_daftar === 'A'"
                  >
                    <v-text-field
                      v-model="pst.jenjang_pendidikan"
                      label="Jenjang Pendidikan"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="pst.nm_sekolah_pt"
                      label="Asal Sekolah / PT"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="pst.alamat_sekolah_pt"
                      label="Alamat Sekolah / PT"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-subheader>Peminatan Program Studi</v-subheader>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :items="kls"
                      color="white"
                      item-text="name"
                      item-value="id"
                      label="Kelas Pilihan"
                      v-model="pst.kelas"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :items="prodies"
                      persistent-hint
                      color="white"
                      item-text="nama_program_studi"
                      item-value="kode_program_studi"
                      label="Pilihan 1"
                      v-model="pst.pilihan_1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :items="prodies"
                      persistent-hint
                      color="white"
                      item-text="nama_program_studi"
                      item-value="kode_program_studi"
                      label="Pilihan 2"
                      v-model="pst.pilihan_2"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-btn
            v-if="item.bukti_bayar_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="kwitansi(item.bukti_bayar_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Kwitansi
          </v-btn>
          <v-btn
            v-if="item.foto_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="foto(item.foto_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Foto
          </v-btn>
          <v-btn
            v-if="item.ijazah_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="pp(item.ijazah_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Ijazah
          </v-btn>
          <v-btn
            v-if="item.kk_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="pp(item.kk_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Kartu keluarga
          </v-btn>
          <v-btn
            v-if="item.ktp_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="ktp(item.ktp_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File KTP
          </v-btn>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
              class="sm-2 mr-2"
              fab
              dark
              x-small
              color="indigo"
            >
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Edit Peserta</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deletePeserta(item)"
              fab
              dark
              x-small
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Hapus Peserta</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.validation`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="validasi(item)"
              class="sm-2"
              fab
              dark
              x-small
              color="indigo"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Validasi Peserta</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <img-viewer ref="viewer" />
  </v-card>
</template>
<script>
import ImgViewer from "../components/ImgViewer";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "peserta",
  components: {
    ImgViewer,
  },
  data: () => ({
    //
    expanded: [],
    singleExpand: true,
    dialog: false,
    editedIndex: -1,
    search: "",
    cari: {
      tahun: "",
      gelombang: "",
    },
    pst: {
      nm_pst: "",
      nik: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      agama: "",
      jenis_kelamin: "",
      pekerjaan: "",
      no_telp: "",
      alamat: "",
      jenis_daftar: "",
      jenjang_pendidikan: "",
      nm_sekolah_pt: "",
      alamat_sekolah_pt: "",
      kelas: "",
      pilihan_1: "",
      pilihan_2: "",
    },
    agama: [
      { name: "Islam", id_agama: "1" },
      { name: "Katolik", id_agama: "3" },
      { name: "Protestan", id_agama: "2" },
      { name: "Hindu", id_agama: "4" },
      { name: "Budha", id_agama: "5" },
      { name: "LainNya", id_agama: "99" },
    ],
    kls: [
      { name: "Khusus", id: "K" },
      { name: "Reguler", id: "R" },
    ],
    asalPendidikan: [
      { name: "Mahasiswa Baru", id: "B" },
      { name: "Mahasiswa Pindahan", id: "P" },
      { name: "Mahasiswa Alih Jenjang", id: "A" },
      { name: "Mahasiswa Pasca Sarjana", id: "X" },
    ],
  }),
  created() {
    this.loadPeserta();
    this.loadProdi();
  },
  computed: {
    ...mapGetters("peserta", ["allPeserta"]),
    ...mapGetters("auth", ["userInfo"]),
    ...mapGetters("reg", ["prodi"]),
    prodies() {
      return Object.keys(this.prodi).map((k) => {
        let obj = this.prodi[k];
        return obj;
      });
    },
    headers() {
      return [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "Nama Peserta",
          value: "nm_pst",
        },
        {
          text: "Kelas",
          value: "kelas",
        },
        {
          text: "Jenis Daftar",
          value: "jenis_daftar",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Validation",
          value: "validation",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions("peserta", ["loadPeserta"]),
    ...mapActions("reg", ["loadProdi"]),
    formData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    editItem(item) {
      this.editedIndex = this.allPeserta.indexOf(item);
      this.pst = Object.assign({}, item);
      this.dialog = true;
    },
    validasi(item) {
      let kode = "ukt-";
      let noUjian = kode + Math.random().toString(36).substring(2, 15);
      this.$confirm(
        "Are you sure validation data " + item.nm_pst + "?",
        "Confirm",
        "question"
      )
        .then(() => {
          this.$store.dispatch("peserta/validasi", {
            id_pst: item.id_pst,
            nomor_ujian: noUjian,
            updateter: this.userInfo.first_name,
          });
          this.$alert(
            "Successful Exam Number Given To " + item.nm_pst,
            "Success",
            "success"
          ).then(() => {
            this.loadPeserta();
          });
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    pp(pp) {
      //window.open(pp, "_blank");
      const imageP = [
        {
          thumbnail: pp,
          source: pp,
        },
      ];
      this.$refs.viewer.show(imageP);
    },
    ktp(ktp) {
      //window.open(pp, "_blank");
      const imageKtp = [
        {
          thumbnail: ktp,
          source: ktp,
        },
      ];
      this.$refs.viewer.show(imageKtp);
    },
    foto(foto) {
      //window.open(foto, "_blank");
      const imagePoto = [
        {
          thumbnail: foto,
          source: foto,
        },
      ];
      this.$refs.viewer.show(imagePoto);
    },
    kwitansi(k) {
      //window.open(k, "_blank");
      const imageK = [
        {
          thumbnail: k,
          source: k,
        },
      ];
      this.$refs.viewer.show(imageK);
    },
    kk(kk) {
      //window.open(kk, "_blank");
      const imageKK = [
        {
          thumbnail: kk,
          source: kk,
        },
      ];
      this.$refs.viewer.show(imageKK);
    },
    close() {
      this.dialog = false;
      this.loadPeserta();
    },
    updatePeserta() {
      this.$store.dispatch("peserta/updatePst", this.pst);
      this.$alert("Data Is Updated", "Success", "success").then(() => {
        this.close();
      });
    },
    deletePeserta(item) {
      this.$confirm(
        "Anda Yakin Akan Menghapus Data : " + item.nm_pst + "?",
        "Confirm",
        "question"
      )
        .then(() => {
          this.$store.dispatch("peserta/removePst", item);
          this.$alert("Data Berhasil Dihapus", "Success", "success");
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
  },
};
</script>
